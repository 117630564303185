export default {
  routeName: 'pre_search',
  preload: true, // 是否预加载， 切换路由时，不会重新请求数据
  newPosKeys: [
    'MostPopular',
    'MoreSearchWord',
    'NewPicSearch',
    'PicSearchUpgrade',
    'SearchPagefromDetail',
    'HomeSearch',
    'SuggestWordUp',
    'SearchTrendNew',
    'HotWordScroll',
    'BackToSugg',
    'searchwordstyle',
    'SearchSuggestNew',
    'SearchDefaultNew',
    'SearchHotGD',
    'SearchHotNew',
    'SearchPerformance',
    'SearchCouponNoti',
    'newpresearch',
    'newpresearchlenovo',
    'newpresearchicon',
  ],
  posKeys: [
    'SearchSuggestwordFeedback',
    'PicSearch',
    'PicSearchStrategy',
    'PicSearchExpandPop',
    'AllListQuickShip',
    'ListSearchSort',
  ],
}
  
