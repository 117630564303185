// 使用文档： wiki.pageId=1062032837

import createInstance from './core/index.js'
import { abtRouter } from './abtRouter.js'

const abtservice = (function getABTServiceInstance () {
    if (typeof window === 'undefined') {
      return createInstance()
    }
    if (!window._abt_server_provider) {
      const abtInstance = createInstance()
      window._abt_server_provider = abtInstance
      abtRouter(abtInstance)
      if (typeof gbCommonInfo !== 'undefined' && gbCommonInfo.isDebug) {
        import(/* webpackChunkName: "abt_debug" */'./abtDebug.js').then(({ abtDebugInstance }) => {
          abtDebugInstance(window._abt_server_provider)
        })
      }
    }
    return window._abt_server_provider
})()


export {
  abtservice,
}
